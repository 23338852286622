import React from 'react'
import { Script } from 'gatsby'

const Auction = () => {
  return (
    <section
      className='component-auction mx-auto w-full max-w-screen-xl
    bg-white p-4 md:my-10 md:rounded'>
      <Script
        id='bid-js-config'
        dangerouslySetInnerHTML={{
          __html: `
            window.bidjs = {
              config: {
                clientId: '${process.env.GATSBY_BIDJS_CLIENT_ID}',
                googleMapsApiKey: '${process.env.GATSBY_GOOGLE_API_KEY}',
                region: '${process.env.GATSBY_BIDJS_REGION}',
                server: '${process.env.GATSBY_BIDJS_SERVER}',
              },
              options: {
                allowMetaDescriptionChange: true,
                allowTitleChange: true,
                imageCropMode: 'pad',
                imagePadColor: 'rgb:ffffff',
                itemsPerPageDefault: 18,
                analyticsCode: '${process.env.GATSBY_COMPANY_GA}',
                analyticsNamespace: 'app',
                enablePaginationTop: true,
                scrollOnNavigationElementId: '___gatsby',
                standalone: false,
                unsupportedBrowserMessage: 'Your browser is not supported. Please use a browser such as Google Chrome or Microsoft Edge to ensure full functionality',
              },
              modules: {
                lotDetails: '/auction/#!/auctions/%AUCTION_ID%/listings/%ITEM_ID%',
                marketplaceLotDetails: '/auction/#!/marketplace/listings/%ITEM_ID%'
              }
            }
          `
        }}
      />
      <div className='bidlogix-app bidjs-app'>
        <div id='bidlogix-modal' />
      </div>
      <div className='bidlogix-app'>
        <div id='bidjs' className='bidjs-app relative' />
      </div>
    </section>
  )
}

export default React.memo(Auction)
