import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

const socialQuery = graphql`
  {
    prismicSocial {
      ...PrismicSocialFragment
    }
  }
`

const Social = () => {
  return (
    <StaticQuery
      query={`${socialQuery}`}
      render={(data) => {
        if (!data.prismicSocial) return null
        const socialData = data.prismicSocial.data
        return (
          <div className='mt-4 flex space-x-3 md:order-2 md:justify-end'>
            {socialData.social.map((social, index) => {
              return (
                <a className='transform rounded-full grayscale hover:grayscale-0' href={social.social_network_url.url} key={`social-${index}`}>
                  {social.social_network.toLowerCase() === 'facebook' && <StaticImage src='../images/facebook.png' alt='Visit us on Facebook' placeholder='blurred' layout='fixed' width={32} />}
                  {social.social_network.toLowerCase() === 'instagram' && <StaticImage src='../images/instagram.png' alt='Visit us on Instagram' placeholder='blurred' layout='fixed' width={32} />}
                  {social.social_network.toLowerCase() === 'linkedin' && <StaticImage src='../images/linkedin.png' alt='Visit us on LinkedIn' placeholder='blurred' layout='fixed' width={32} />}
                  {social.social_network.toLowerCase() === 'twitter' && <StaticImage src='../images/twitter.png' alt='Visit us on twitter' placeholder='blurred' layout='fixed' width={32} />}
                </a>
              )
            })}
          </div>
        )
      }}
    />
  )
}

export default Social
