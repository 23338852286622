import React from 'react'
import { graphql } from 'gatsby'
import { PrismicLink, PrismicRichText } from '@prismicio/react'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

// Components
import Layout from '../components/layout'
import Seo from '../components/seo'
import SliceList from '../components/sliceList'

export const query = graphql`
  query Page($id: String) {
    prismicPage(id: { eq: $id }) {
      ...PrismicPageFragment
    }
  }
`

const PageTemplate = ({ data, location }) => {
  const page = data.prismicPage
  const pageData = page.data

  let images = pageData.page_image?.gatsbyImageData
  if (pageData.page_image.thumbnails) {
    images = withArtDirection(getImage(pageData.page_image.gatsbyImageData), [
      {
        media: '(max-width: 768px)',
        image: getImage(pageData.page_image.thumbnails.mobile.gatsbyImageData)
      }
    ])
  }

  return (
    <Layout path={location.pathname} lang={pageData.lang}>
      <Seo title={pageData.meta_title ? pageData.meta_title : pageData.title} keywords={pageData.meta_keywords} description={pageData.meta_description} lang={pageData.lang} />
      {pageData.page_image.gatsbyImageData ? (
        <div className='hero grid h-full min-h-screen-1/2 grid-cols-1 grid-rows-1 overflow-hidden bg-white md:max-h-screen-2/3 md:min-h-screen-2/3'>
          <div className='art-directed relative col-span-full row-span-full h-full'>
            <GatsbyImage className='absolute inset-0 h-full w-full object-cover' image={images} alt={pageData.page_image.alt || ''} />
          </div>
          <div className='relative col-span-full row-span-full w-full'>
            <div className='mx-auto flex h-full max-w-screen-xl flex-col items-start justify-center'>
              <div className='relative mx-4 flex w-auto flex-col items-start justify-center gap-4 bg-white bg-opacity-50 p-10 py-4 md:max-w-screen-lg lg:col-span-2 lg:px-8 lg:py-10'>
                <div className='uppercase text-brand-blue drop-shadow-[1px_1px_0px_rgba(255,255,255,1)]'>
                  <PrismicRichText field={pageData.page_title.richText} />
                </div>
                <PrismicRichText field={pageData.page_text.richText} />
                <PrismicLink className='button button-primary text-lg' field={pageData.page_button_link}>
                  {pageData.page_button_text}
                </PrismicLink>
              </div>
            </div>
          </div>
        </div>
      ) : (
        (pageData.page_title.text || pageData.page_text.text) && (
          <div className='mx-auto w-full max-w-screen-xl py-10 px-4 text-center text-sky-50'>
            <PrismicRichText field={pageData.page_title.richText} />
            <PrismicRichText field={pageData.page_text.richText} />
          </div>
        )
      )}
      <SliceList slices={pageData.body} />
    </Layout>
  )
}

export default withPrismicPreview(PageTemplate)
