import React from 'react'
import PropTypes from 'prop-types'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'

const BannerWithCaption = ({ slice }) => {
  return (
    <section className='component-banner_with_caption mx-auto w-full max-w-screen-xl p-4 md:px-10'>
      <div className='relative grid overflow-hidden rounded-sm bg-white p-4 shadow md:grid-cols-2 lg:grid-cols-3'>
        <div className='relative flex flex-col items-start justify-center gap-4 py-4 lg:col-span-2 lg:px-8 lg:py-10'>
          <PrismicRichText field={slice.primary.title.richText} />
          <div className='prose prose-lg max-w-none'>
            <PrismicRichText field={slice.primary.text.richText} />
          </div>
          <PrismicLink field={slice.primary.button_link} className='button button-secondary'>
            {slice.primary.button_text}
          </PrismicLink>
        </div>
        <div>
          <GatsbyImage image={slice.primary.image.gatsbyImageData} className='h-full w-full' alt={slice.primary.image.alt || ''} />
        </div>
      </div>
    </section>
  )
}

BannerWithCaption.propTypes = {
  slice: PropTypes.object.isRequired
}

export default BannerWithCaption
