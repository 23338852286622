import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import { PrismicRichText, PrismicLink } from '@prismicio/react'

// Components
import Mailchimp from './mailchimp'
import Social from './social'

const footerQuery = graphql`
  {
    prismicNavigation(lang: { eq: "en-gb" }) {
      ...PrismicNavigationFragment
    }
  }
`

const Footer = () => {
  return (
    <StaticQuery
      query={`${footerQuery}`}
      render={(data) => {
        const footerData = data.prismicNavigation.data
        return (
          <>
            <Mailchimp />
            <footer role='contentinfo' className='mt-auto bg-white'>
              <div className='mx-auto grid max-w-screen-xl grid-cols-1 gap-12 px-6 py-10 md:grid-cols-4'>
                <div className='md:col-span-2'>
                  <div className='mb-4 font-serif font-semibold'>
                    {footerData.company_display_name} &copy; {new Date().getFullYear()}
                  </div>
                  <PrismicRichText field={footerData.company_address.richText} />
                  <PrismicLink className='py-2 text-brand-blue hover:underline' href={`mailto:${footerData.company_phone}`}>
                    {footerData.company_phone}
                  </PrismicLink>
                </div>
                {footerData.body.map((footer, index) => {
                  return (
                    <div key={`footer-${index}`}>
                      <div className='mb-4 font-serif font-semibold'>{footer.primary.title}</div>
                      <ul>
                        {footer.items.map((footerItem, index) => {
                          return (
                            <li className='my-2' key={`footerItem-${index}`}>
                              <PrismicLink className='py-2 text-brand-blue hover:underline' field={footerItem.link}>
                                {footerItem.link_label}
                              </PrismicLink>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  )
                })}
                <Social />
              </div>
            </footer>
          </>
        )
      }}
    />
  )
}

export default Footer
