import React from 'react'
import { SliceZone } from '@prismicio/react'

// Slices
import Accordion from './accordion'
import Auction from './auction'
import BannerWithCaption from './bannerWithCaption'
import CardDeck from './cardDeck'
import Contact from './contact'
import Counter from './counter'
import Hero from './hero'
import Image from './image'
import Map from './map'
import Quote from './quote'
import Text from './text'

const components = {
  accordion: Accordion,
  auction: Auction,
  banner_with_caption: BannerWithCaption,
  card_deck: CardDeck,
  contact: Contact,
  counter: Counter,
  hero: Hero,
  image_gallery: Image,
  map: Map,
  quote: Quote,
  text: Text
}

const SliceList = ({ slices }) => {
  return <SliceZone slices={slices} components={components} defaultComponent={() => null} />
}

export default React.memo(SliceList)
