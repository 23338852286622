import * as React from 'react'
import { Link } from 'gatsby'
import { PrismicProvider } from '@prismicio/react'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'

// Utils
import { repositoryConfigs } from './src/utils/prismicPreviews'
import { linkResolver } from './src/utils/linkResolver'

import '@fontsource/domine'
import '@fontsource/mulish'
import './src/styles/default.css'

export const wrapRootElement = ({ element }) => (
  <PrismicProvider
    linkResolver={linkResolver}
    externalLinkComponent={(props) => (
      <a data-location='external' {...props}>
        {props.children}
      </a>
    )}
    internalLinkComponent={({ href, ...props }) => {
      if (href.includes('auction')) {
        return (
          <a data-location='auction' href={href} {...props}>
            {props.children}
          </a>
        )
      }
      return <Link to={href} {...props} />
    }}>
    <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>{element}</PrismicPreviewProvider>
  </PrismicProvider>
)
