import React, { useState } from 'react'
import { Fade as Hamburger } from 'hamburger-react'
import { StaticImage } from 'gatsby-plugin-image'
import { graphql, Link, StaticQuery } from 'gatsby'
import { PrismicLink } from '@prismicio/react'

// Components
import BidJSMenu from './bidjsMenu'
import Dropdown from './dropdown'

const headerQuery = graphql`
  {
    prismicNavigation(lang: { eq: "en-gb" }) {
      ...PrismicNavigationFragment
    }
  }
`

const Header = (props) => {
  const [isOpen, setOpen] = useState(false)

  let navStyles =
    'flex text-xl w-full flex-row justify-between items-center py-2 px-4 font-medium text-gray-700 border-transparent border-b-2 hover:text-brand-blue hover:border-brand-blue focus:text-gray-900'
  let subNavStyles =
    'flex text-xl flex-row justify-between items-center whitespace-nowrap py-2 px-4 font-medium text-gray-700 transition duration-150 ease-in-out hover:text-brand-blue focus:text-gray-900'

  return (
    <StaticQuery
      query={`${headerQuery}`}
      render={(data) => {
        const headerData = data.prismicNavigation.data
        return (
          <header role='banner' className='absolute top-0 left-0 right-0 z-20 bg-white text-black shadow-md'>
            <nav className='relative mx-auto flex max-w-screen-xl items-center p-4'>
              <Link to='/' className='relative z-20 mr-6 py-4 text-3xl tracking-tight text-brand-blue lg:text-5xl'>
                <StaticImage height={60} src='../images/C&CLogo-inline.png' alt={`${headerData.company_display_name} logo`} placeholder='blurred' layout='constrained' />
              </Link>
              <span className='z-30 ml-auto lg:hidden'>
                <Hamburger color='#003882' label='Show menu' rounded toggled={isOpen} toggle={setOpen} />
              </span>
              <div
                className={`${
                  !isOpen ? 'tw-hidden' : 'fixed inset-0 z-10 flex h-screen flex-col justify-start bg-black bg-opacity-25 p-2'
                } lg:relative lg:flex lg:h-auto lg:w-full lg:flex-row lg:bg-transparent`}>
                <div className='lg:max-h-none max-h-screen w-full rounded bg-white shadow-md lg:rounded-none lg:bg-transparent lg:shadow-none'>
                  <ul className='relative w-full flex-row items-center pt-28 lg:flex lg:pt-0'>
                    {headerData.nav.map((nav, index) => {
                      return (
                        <React.Fragment key={`nav-${index}`}>
                          {nav.items.length > 1 ? (
                            <Dropdown navStyles={navStyles} subNavStyles={subNavStyles} items={nav.items}>
                              {nav.primary.label}
                            </Dropdown>
                          ) : (
                            <li key={`nav-${index}`}>
                              <PrismicLink className={navStyles} field={nav.primary.link} activeClassName='text-brand-primary'>
                                {nav.primary.label}
                              </PrismicLink>
                            </li>
                          )}
                        </React.Fragment>
                      )
                    })}
                    <li className='x-bidlogix--authenticated-hide relative z-10 m-2 mb-4 hidden px-2 lg:mb-0 lg:ml-auto lg:mt-0 lg:mr-0 lg:px-0'>
                      <a href='../auction/#!/login' className='button button-primary block text-xl lg:text-lg xl:inline-block'>
                        Log In
                      </a>
                    </li>
                    <BidJSMenu navStyles={navStyles} subNavStyles={subNavStyles} />
                  </ul>
                </div>
              </div>
            </nav>
          </header>
        )
      }}
    />
  )
}

export default Header
