import React from 'react'
import PropTypes from 'prop-types'
import { PrismicLink, PrismicRichText } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'

const Hero = ({ slice }) => {
  return (
    <section className='component-hero py-10'>
      <div className='grid grid-cols-1 grid-rows-1 overflow-hidden bg-white md:grid-cols-2'>
        {slice.primary.image.gatsbyImageData && (
          <div className='relative col-span-full h-full md:row-span-full'>
            <GatsbyImage className='absolute inset-0 h-full w-full object-cover' image={slice.primary.image.gatsbyImageData} alt={slice.primary.image.alt || ''} />
          </div>
        )}
        <div className='hero relative col-span-full flex w-full flex-col justify-center py-32 px-4 text-center md:row-span-full md:mx-auto md:max-w-screen-xl md:text-left'>
          <div className='flex flex-col items-start justify-center gap-4 md:max-w-screen-md'>
            <PrismicRichText field={slice.primary.title.richText} />
            <PrismicRichText field={slice.primary.text.richText} />
            <PrismicLink className='button' field={slice.primary.button_link}>
              {slice.primary.button_text}
            </PrismicLink>
          </div>
        </div>
      </div>
    </section>
  )
}

Hero.propTypes = {
  slice: PropTypes.object.isRequired
}

export default Hero
