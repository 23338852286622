import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import useOnClickOutside from './hooks/useOnClickOutside'

const BidJSMenu = ({ navStyles, subNavStyles }) => {
  const ref = useRef()
  useOnClickOutside(ref, () => setAccountOpen(false))

  const [isAccountOpen, setAccountOpen] = useState(false)
  const accountToggle = () => setAccountOpen(!isAccountOpen)

  return (
    <li ref={ref} className='x-bidlogix--authenticated-show relative hidden lg:ml-auto'>
      <button aria-expanded={isAccountOpen} aria-haspopup='true' className={navStyles} onClick={accountToggle} onKeyDown={accountToggle}>
        <svg className='mr-3 w-5' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 496 512' data-fa-i2svg=''>
          <path
            fill='currentColor'
            d='M248 104c-53 0-96 43-96 96s43 96 96 96 96-43 96-96-43-96-96-96zm0 144c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm0-240C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-49.7 0-95.1-18.3-130.1-48.4 14.9-23 40.4-38.6 69.6-39.5 20.8 6.4 40.6 9.6 60.5 9.6s39.7-3.1 60.5-9.6c29.2 1 54.7 16.5 69.6 39.5-35 30.1-80.4 48.4-130.1 48.4zm162.7-84.1c-24.4-31.4-62.1-51.9-105.1-51.9-10.2 0-26 9.6-57.6 9.6-31.5 0-47.4-9.6-57.6-9.6-42.9 0-80.6 20.5-105.1 51.9C61.9 339.2 48 299.2 48 256c0-110.3 89.7-200 200-200s200 89.7 200 200c0 43.2-13.9 83.2-37.3 115.9z'></path>
        </svg>
        My Account
        <div className='ml-auto text-brand-blue'>
          <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
            <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M19 9l-7 7-7-7' />
          </svg>
        </div>
      </button>
      <ul className={`${isAccountOpen ? 'min-w-max flex flex-col bg-sky-50 md:absolute md:right-0 md:mt-2 md:rounded md:p-3 md:shadow-md' : 'hidden'}`}>
        <li key='settings'>
          <a href='../auction/#!/account' className={subNavStyles} onClick={() => accountToggle()}>
            My Settings
          </a>
        </li>
        <li key='invoice'>
          <a href='../auction/#!/invoices' className={subNavStyles} onClick={() => accountToggle()}>
            My Invoices
          </a>
        </li>
        <li key='auctionsArchived'>
          <a className={subNavStyles} href='../auction/#!/auctionsArchived' onClick={() => accountToggle()}>
            Archived Auctions
          </a>
        </li>
        <li key='admin' className='x-bidlogix--administrator-show hidden'>
          <a href='#' className={subNavStyles} target='_blank' rel='noopener noreferrer' onClick={() => accountToggle()}>
            Admin
          </a>
        </li>
        <li key='seperator' className='my-2 border-t' role='separator'></li>
        <li key='logout'>
          <a href='../auction/#!/logout' className={subNavStyles} onClick={() => accountToggle()}>
            Log Out
            <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5 text-gray-500' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
              <path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1' />
            </svg>
          </a>
        </li>
      </ul>
    </li>
  )
}

BidJSMenu.propTypes = {
  navStyles: PropTypes.string,
  subNavStyles: PropTypes.string
}

export default BidJSMenu
