import React from 'react'
import PropTypes from 'prop-types'
import { PrismicRichText, PrismicLink } from '@prismicio/react'
import { GatsbyImage } from 'gatsby-plugin-image'

const Card = ({ fields }) => {
  return (
    <li className='component-card relative flex flex-grow transform flex-col rounded-sm bg-white p-4 shadow transition duration-150 ease-in-out'>
      {fields.image.gatsbyImageData && (
        <div className='transform overflow-hidden transition duration-500'>
          <GatsbyImage image={fields.image.gatsbyImageData} alt={fields.image.alt || ''} />
        </div>
      )}
      {(fields.title.text || fields.text.text) && (
        <div className='m-4 flex flex-grow flex-col gap-4'>
          <PrismicRichText field={fields.title.richText} />
          <div className='prose prose-lg max-w-none'>
            <PrismicRichText field={fields.text.richText} />
          </div>
          <div className='mt-auto text-center'>
            <PrismicLink className='button button-secondary' field={fields.button_link}>
              {fields.button_text ? fields.button_text : 'see more'}
            </PrismicLink>
          </div>
        </div>
      )}
    </li>
  )
}

Card.propTypes = {
  fields: PropTypes.object.isRequired
}

export default Card
