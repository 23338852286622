import React from 'react'
import { PrismicRichText } from '@prismicio/react'

const Contact = ({ slice }) => {
  return (
    <section className='component-contact mx-auto w-full max-w-screen-xl p-4 md:py-10'>
      <div className='rounded bg-white'>
        <div className='relative mx-auto grid max-w-7xl gap-4 p-4 md:grid-cols-2'>
          <div className='px-2'>
            <div className='prose prose-lg'>
              <PrismicRichText field={slice.primary.text.richText} />
            </div>
            <form name='contact' method='POST' action='/success' data-netlify='true' data-netlify-honeypot='surname'>
              <input type='hidden' name='surname' />
              <input type='hidden' name='form-name' value='contact' />
              <div className='mb-4'>
                <label className='mt-4 mb-2 block font-semibold' htmlFor='name'>
                  What's your name?
                </label>
                <input className='w-full appearance-none rounded border p-3 leading-tight text-gray-700 shadow focus:border-brand-blue' type='text' name='name' id='name' required />
              </div>
              <div className='mb-4'>
                <label className='mt-4 mb-2 block font-semibold' htmlFor='email'>
                  What's your email address?
                </label>
                <input className='w-full appearance-none rounded border p-3 leading-tight text-gray-700 shadow focus:border-brand-blue' type='email' name='email' id='email' required />
              </div>
              <div className='mb-4'>
                <label className='mt-4 mb-2 block font-semibold' htmlFor='message'>
                  How can we help?
                </label>
                <textarea rows='4' className='w-full appearance-none rounded border p-3 leading-tight text-gray-700 shadow focus:border-brand-blue' name='message' id='message' required></textarea>
              </div>
              <div className='mb-4'>
                <button type='submit' className='button button-secondary'>
                  Send your enquiry
                </button>
              </div>
            </form>
          </div>
          <div>
            <iframe
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2394.2207426489776!2d-1.2386661485490185!3d53.12418289893111!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487995d764b36cd1%3A0x382809071e90ad5c!2sCarpenter%20%26%20Cartwright%20Furniture%20Auctions%20Ltd!5e0!3m2!1sen!2suk!4v1645696239544!5m2!1sen!2suk'
              height='100%'
              width='100%'
              loading='lazy'
              title='Location of Carpenter & Cartwright sale room'
            />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact
