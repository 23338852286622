import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'
import CookieConsent from 'react-cookie-consent'

// Components
import BidJsScripts from './bidJsScripts'
import Header from './header'
import Footer from './footer'

const layoutQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const Layout = ({ children, lang, path }) => {
  return (
    <StaticQuery
      query={`${layoutQuery}`}
      render={(data) => (
        <div className='min-h-screen layout flex flex-col bg-brand-blue tracking-tight text-gray-700 antialiased'>
          <Header lang={lang} />
          <main role='main' id='main' className='mt-28'>
            {children}
          </main>
          <Footer lang={lang} />
          <CookieConsent
            location='bottom'
            buttonText='Accept'
            declineButtonText='Decline'
            disableStyles
            buttonClasses='mx-1 px-4 py-2 inline-block rounded-lg border transform transition duration-150 ease-in-out border-white text-white hover:shadow-sm hover:-translate-y-1 hover:shadow-md'
            declineButtonClasses='mx-1 px-4 py-2 inline-block'
            containerClasses='fixed inset-x-0 text-white bg-gray-900 p-3 text-sm text-center z-10'
            contentClasses='inline-block mb-2'
            enableDeclineButton
            cookieName='gatsby-gdpr-google-analytics'>
            <p>
              We use cookies to collect information about how you use {process.env.GATSBY_COMPANY_NAME}. We use this information to make the website work as well as possible and improve our services.
              Please see our{' '}
              <Link to='/privacy-policy/' className='underline'>
                Privacy Policy
              </Link>{' '}
              for more information.
            </p>
          </CookieConsent>
          <BidJsScripts path={path} />
        </div>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  lang: PropTypes.string
}

export default Layout
