import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import PropTypes from 'prop-types'

// Components
import Card from './card'

const CardDeck = ({ slice }) => {
  return (
    <section className='component-card_deck mx-auto w-full max-w-screen-xl py-10 px-4'>
      <PrismicRichText field={slice.primary.deck_title.richText} />
      <ul className='grid grid-cols-1 gap-4 md:grid-cols-2'>
        {slice.items.map((card, index) => {
          return <Card fields={card} key={`card-${index}`} />
        })}
      </ul>
    </section>
  )
}

CardDeck.propTypes = {
  slice: PropTypes.object.isRequired
}

export default CardDeck
