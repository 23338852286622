import React from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

export default class Mailchimp extends React.Component {
  state = {
    email: null
  }

  _handleChange = (e) => {
    this.setState({
      [`${e.target.name}`]: e.target.value
    })
  }
  _handleSubmit = (e) => {
    e.preventDefault()

    console.log('submit', this.state)
    addToMailchimp(this.state.email)
      .then(({ msg, result }) => {
        console.log('msg', `${result}: ${msg}`)
        if (result !== 'success') {
          throw msg
        }
        alert(msg)
      })
      .catch((err) => {
        console.log('err', err)
        alert(err)
      })
  }

  render() {
    return (
      <div className='component-mailchimp bg-white bg-opacity-60 py-10 px-4 text-center'>
        <div className='mx-auto max-w-lg'>
          <div className='mb-4'>
            <h2 className='text-bold text-4xl text-brand-blue drop-shadow-[1px_1px_0px_rgba(255,255,255,1)]'>Signup to our mailing list</h2>
            <p className='text-xl'>Get the latest news and offers from Carpenter & Cartwright first</p>
          </div>
          <form className='flex flex-row' onSubmit={this._handleSubmit}>
            <label className='sr-only' htmlFor='email'>
              Email Address
            </label>
            <input
              className='w-full appearance-none border border-primary p-3 leading-tight text-gray-700 shadow '
              type='email'
              name='email'
              placeholder='email address'
              id='email'
              aria-required='true'
              onChange={this._handleChange}
            />
            <button className='button button-primary' type='submit' name='subscribe' id='mc-embedded-subscribe'>
              Subscribe
            </button>
          </form>
        </div>
      </div>
    )
  }
}
